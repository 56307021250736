import { useMemo } from "react";
import {
  Button,
  Paper,
  Link,
  Typography,
  Grid2 as Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";

import formatPrice from "../../../utils/formatPrice";
import { Watercraft } from "auditaware-types";
import useConfig from "../../../hooks/useConfig";

interface PriceReportTableProps { watercraft: Watercraft; caseId: string; }

const PriceReportTable = ({ watercraft, caseId }: PriceReportTableProps) => {
  const { valuationClient } = useConfig();

  const title = useMemo(() => {
    const {
      year = "{year}",
      make = "{make unknown}",
      trimName = "{trim unknown}",
    } = watercraft;
    return `${year} ${make} ${trimName}`;
  }, [
    watercraft
  ]);

  return (
    <Grid container>
      <Grid container size={12} justifyContent="space-between" sx={{ my: 2, px: 2 }}>
        <Grid size={{ xs: 12, md: 6 }}>
          <Typography variant="h6">{title}</Typography>
        </Grid>
        <Grid size={{ xs: 12, md: 6 }} container justifyContent="flex-end">
          <Link
            href={watercraft
              ? `${valuationClient}watercraft/${caseId}/${watercraft.id}`
              : `${valuationClient}watercraft/${caseId}`}
            sx={{ textDecoration: "none" }} >
            <Button
              type="submit"
              variant="text"
              color="primary"
              startIcon={watercraft ? <EditIcon /> : <AddIcon />}
              sx={{ borderRadius: 24 }} >
              {watercraft ? "Edit" : "Add"}
            </Button>
          </Link>
        </Grid>
      </Grid>
      <Grid size={{ xs: 12, md: 6 }} sx={{ pr: 2, mb: 2 }}>
        <TableContainer>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>
                  <b>Price Report</b>
                </TableCell>
                <TableCell align="right">Price</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow hover={true}>
                <TableCell component="th" scope="row">
                  Average Retail
                </TableCell>
                <TableCell align="right">
                  {formatPrice(watercraft.averageRetail)}
                </TableCell>
              </TableRow>
              <TableRow hover={true}>
                <TableCell component="th" scope="row">
                  High Retail
                </TableCell>
                <TableCell align="right">
                  {formatPrice(watercraft.highRetail)}
                </TableCell>
              </TableRow>
              <TableRow hover={true}>
                <TableCell component="th" scope="row">
                  Trade In
                </TableCell>
                <TableCell align="right">
                  {formatPrice(watercraft.tradeIn)}
                </TableCell>
              </TableRow>
              <TableRow hover={true}>
                <TableCell component="th" scope="row">
                  Suggested List
                </TableCell>
                <TableCell align="right">
                  {formatPrice(watercraft.suggestedList)}
                </TableCell>
              </TableRow>
              <TableRow hover={true}>
                <TableCell component="th" scope="row">
                  Engine Price Adjustment
                </TableCell>
                <TableCell align="right">
                  {formatPrice(watercraft.enginePriceAdjustment)}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid size={{ xs: 12, md: 6 }} sx={{ pl: 2 }}>
        {watercraft.options ? (
          <TableContainer sx={{ maxHeight: "20.2rem" }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell sx={{}}>
                    <b>Selected Options</b>
                  </TableCell>
                  <TableCell align="right">Average</TableCell>
                  <TableCell align="right">High</TableCell>
                  <TableCell align="right">Trade In</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {watercraft.options.map((option) => (
                  <TableRow key={option.optionCode} hover={true}>
                    <TableCell component="th" scope="row">
                      {option.displayName}
                    </TableCell>
                    <TableCell align="right">
                      {formatPrice(option?.averageRetail || 0)}
                    </TableCell>
                    <TableCell align="right">
                      {formatPrice(option?.highRetail || 0)}
                    </TableCell>
                    <TableCell align="right">
                      {formatPrice(option?.tradeIn || 0)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <h3>No Options Selected</h3>
        )}
      </Grid>
      {watercraft.priceTypes && (
        <Grid size={12} sx={{ fontSize: "0.8rem" }}>
          <Paper sx={{ py: 1, px: 2 }}>
            <h3>Price Guidelines</h3>
            {watercraft.priceTypes.map((priceType, index) => (
              <div key={index}>
                <p
                  dangerouslySetInnerHTML={{
                    __html: priceType.priceTypeExplanation || "",
                  }}
                />
              </div>
            ))}
          </Paper>
        </Grid>
      )}
    </Grid>
  );
};

export default PriceReportTable;
