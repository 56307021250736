import { FC } from "react";
import { SelectField, SelectFieldProps } from "auditaware-ui";
import { CaseType } from "auditaware-types";

export const options = Object.values(CaseType);

const CaseTypeSelect: FC<SelectFieldProps> = ({
  name,
  label,
  required = false,
  id,
  autoComplete,
}) => {
  const props = {
    name,
    required,
    id,
    label,
    autoComplete,
    options,
  };
  return <SelectField {...props} />;
};

export default CaseTypeSelect;
