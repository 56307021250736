import { FC } from "react";
import { Button, Paper, Link, Typography, Grid2 as Grid } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import BoxTop from "../BoxTop";
import BoxHeader from "../BoxHeader";
import OverviewBox from "../OverviewBox";
import { Watercraft } from "auditaware-types";
import useConfig from "../../../hooks/useConfig";
import PriceReportTable from "./PriceReportTable";

interface CaseWatercraft {
  watercrafts: Watercraft[]
  caseId: string
}

const PriceReport: FC<CaseWatercraft> = ({ watercrafts, caseId }) => {
  const { valuationClient } = useConfig();
  return (
    <Grid size={12} sx={{ mt: 1 }}>
      <Paper>
        <Grid size={12}>
          <BoxTop>
            <BoxHeader>
              Watercraft
            </BoxHeader>
            {watercrafts.length <= 0 && (
              <Link
                href={`${valuationClient}watercraft/${caseId}`}
                sx={{ textDecoration: "none" }} >
                <Button
                  type="submit"
                  variant="text"
                  color="primary"
                  startIcon={<AddIcon />}
                  sx={{ borderRadius: 24 }} >
                  Add Watercraft
                </Button>
              </Link>
            )}
          </BoxTop >
        </Grid>
        {watercrafts.length > 0 ? (
          watercrafts.map((w) => (
            <OverviewBox key={w.id}>
              <Grid container size={12} sx={{ mt: -2, pt: 2 }}>
                {w
                  ? <PriceReportTable watercraft={w} caseId={caseId} />
                  : <h3>No Watercraft found</h3>}
              </Grid>
            </OverviewBox>
          ))
        ) : (
          <OverviewBox>
            <Grid container size={12} sx={{ mt: -2, pt: 2 }}>
              <Typography component="p" sx={{ py: 2 }}>
                No Watercraft available
              </Typography>
            </Grid>
          </OverviewBox>
        )}
      </Paper>
    </Grid >
  );
};

export default PriceReport;
