"use strict";
/* eslint-disable no-unused-vars */
/* the above is falsly triggered because it doesn't recognize exports usage */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CaseType = void 0;
var CaseType;
(function (CaseType) {
    CaseType["Audit"] = "Audit";
    CaseType["Inspection"] = "Inspection";
    CaseType["Review"] = "Review";
    CaseType["Appraisal"] = "Appraisal";
    CaseType["Discovery"] = "Discovery";
    CaseType["ESA"] = "ESA";
    CaseType["Watercraft"] = "Watercraft";
})(CaseType || (exports.CaseType = CaseType = {}));
