import React, { FC } from "react";
import { Button, Grid2 as Grid, Paper } from "@mui/material";
import { EditNote } from "@mui/icons-material";

import { Case } from "auditaware-types";
import { Drawer, useDrawerControls } from "auditaware-ui";

import useFormattedDate from "../../../hooks/useFormattedDate";
import BoxTop from "../BoxTop";
import BoxHeader from "../BoxHeader";
import OverviewBox from "../OverviewBox";
import { Item, List } from "../List";
import EditFees from "./EditFees";

type CaseFeesProps = {
  caseData: Case;
};

const CaseFees: FC<CaseFeesProps> = ({ caseData }) => {
  const feeInvoicedDate = useFormattedDate(caseData.feeInvoicedOn);

  const [feesDrawerProps, openFeesDrawer, closeFeesDrawer] = useDrawerControls({
    title: "Edit Fees",
  });

  return (
    <Paper>
      <BoxTop>
        <BoxHeader>Fees</BoxHeader>
        <Button
          variant="text"
          color="primary"
          startIcon={<EditNote />}
          onClick={openFeesDrawer}
        >
          Edit
        </Button>
        <Drawer {...feesDrawerProps}>
          <EditFees caseData={caseData} closeDrawer={closeFeesDrawer} />
        </Drawer>
      </BoxTop>
      <OverviewBox>
        <Grid container spacing={2}>
          <Grid size={6}>
            <List>
              <Item primary="Fee Amount" secondary={caseData.feeAmount || ""} />
              <Item primary="Fee Invoiced" secondary={feeInvoicedDate || ""} />
              <Item primary="Fee Size" secondary={caseData.feeSize} />
            </List>
          </Grid>
        </Grid>
      </OverviewBox>
    </Paper>
  );
};

export default CaseFees;
