import { gql, useMutation, useQuery } from "@apollo/client";

import { Subject, SubjectInput } from "auditaware-types";
import { useOrganization } from "auditaware-auth";

import { casesFragment as CasesFragment } from "./cases";

export const SubjectFragment = gql`
  fragment SubjectsFragment on Subject {
    id
    externalId
    locationId
    subjectType
    firstAuditYear
    organizationId
    createdByUid
    additionalInformation
    subjectData {
      type
      ... on ResidenceData {
        score
        exemptionPercentage
        lastSaleOn
      }
    }
    contact {
      primaryName
      secondaryName
      aliasName
      website
      phoneNumber
      email
      naics
    }
    location {
      parcelId
      address
      city
      stateAbbr
      zipCode
      countyName
      jurisdictionName
    }
    subjectData {
      type
      ... on ResidenceData {
        exemptionPercentage
        score
        lastSaleOn
      }
    }
  }
`;

export const GET_SUBJECTS = gql`
  query GetSubjects($orgId: ID!) {
    organization(id: $orgId) {
      id
      subjects {
        ...SubjectsFragment
      }
    }
  }
  ${SubjectFragment}
`;

export const GET_SUBJECT_AND_CASES = gql`
  query GetSubjects($orgId: ID!) {
    organization(id: $orgId) {
      id
      subjects {
        ...SubjectsFragment
        cases {
          ...CasesFragment
        }
      }
    }
  }
  ${SubjectFragment}
  ${CasesFragment}
`;

export const GET_SUBJECT = gql`
  query GetSubject($orgId: ID!, $id: ID!) {
    organization(id: $orgId) {
      id
      subject(id: $id) {
        ...SubjectsFragment
        cases {
          ...CasesFragment

          esaValuations {
            id
            caseId
            year
            advOriginalValue
            advAuditedValue
            fmvOriginalValue
            fmvAuditedValue
          }


        }
      }
    }
  }
  ${SubjectFragment}
  ${CasesFragment}
`;

const CREATE_SUBJECT = gql`
  mutation CreateSubject($orgId: ID!, $input: SubjectInput!) {
    createSubject(orgId: $orgId, input: $input) {
      id
    }
  }
`;

const UPDATE_SUBJECT = gql`
  mutation UpdateSubject($id: ID!, $input: SubjectInput!) {
    updateSubject(id: $id, input: $input) {
      id
    }
  }
`;

type SubjectsQueryResponse = {
  organization: {
    subjects: Subject[];
  };
};

type SubjectQueryResponse = {
  organization: {
    subject: Subject;
  };
};

type CreateSubjectResponse = {
  createSubject: {
    id: string;
  };
};

type UpdateSubjectResponse = {
  updateSubject: {
    id: string;
  };
};

type UpdateSubjectVariables = {
  id: string;
  input: SubjectInput;
};

export const useSubjects = ({ loadCases }: { loadCases?: boolean } = {}) => {
  const org = useOrganization();

  const { loading, error, data } = useQuery<SubjectsQueryResponse>(
    loadCases ? GET_SUBJECT_AND_CASES : GET_SUBJECTS,
    {
      variables: { orgId: org.id },
    }
  );
  const subjects = data?.organization?.subjects || [];

  return { loading, error, subjects };
};

export const useSubject = (id: string) => {
  const org = useOrganization();
  return useQuery<SubjectQueryResponse>(GET_SUBJECT, {
    skip: !org,
    variables: { id, orgId: org?.id || "" },
  });
};

export const useCreateSubject = () => {
  const orgId = useOrganization().id;
  return useMutation<CreateSubjectResponse>(CREATE_SUBJECT, {
    refetchQueries: [GET_SUBJECTS, GET_SUBJECT_AND_CASES],
    onError: (error) => {
      throw error;
    },
    variables: { orgId },
  });
};

export const useUpdateSubject = () => {
  return useMutation<UpdateSubjectResponse, UpdateSubjectVariables>(UPDATE_SUBJECT, {
    refetchQueries: [GET_SUBJECT],
  });
};
