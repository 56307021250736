import { FC } from "react";
import {
  List,
  ListItem,
  Paper,
  Button,
  Typography,
  Divider,
  Grid2 as Grid,
} from "@mui/material";

import { CaseType, ESAValuation } from "auditaware-types";
import { CustomDrawer, useDrawer } from "auditaware-ui";

import EditValuation from "../EsaValuation/EditEsaValuation";
import OverviewBox from "../OverviewBox";
import BoxHeader from "../BoxHeader";
import BoxTop from "../BoxTop";
import { Item } from "../List";
import { EditNote } from "@mui/icons-material";

interface CaseValuationsProps {
  valuations: ESAValuation[];
  caseId: string;
  caseType: CaseType | null;
  caseCreatedAt: Date;
  caseFirstAuditYear?: number | null;
}

const itemCurrency = (value: number) =>
  new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(value);

const itemPercentage = (firstValue: number, secondValue: number) => {
  if (firstValue === 0) {
    return "0%";
  }

  return `${(((secondValue - firstValue) / firstValue) * 100).toFixed(2)}%`;
};

const CaseValuation: FC<{ valuation: ESAValuation }> = ({ valuation }) => {
  return (
    <ListItem alignItems="flex-start">
      <Grid
        container
        direction="row"
        spacing={2}
        size={12}
        justifyContent="space-between"
      >
        <Grid size={6}>
          <Typography variant="h6" sx={{ mb: 2, textAlign: "center" }}>
            Ad Valorem
          </Typography>
          <List>
            <Item
              primary="Ad Valorem Original Value"
              secondary={itemCurrency(valuation.advOriginalValue)}
            />
            <Item
              primary="Ad Valorem Audited Value"
              secondary={itemCurrency(valuation.advAuditedValue)}
            />
            <Item
              primary="Difference"
              secondary={itemCurrency(
                valuation.advAuditedValue - valuation.advOriginalValue
              )}
            />
            <Item
              primary="Difference (%)"
              secondary={itemPercentage(
                valuation.advOriginalValue,
                valuation.advAuditedValue
              )}
            />
          </List>
        </Grid>
        <Grid size={6} justifyContent="flex-end">
          <Typography variant="h6" sx={{ mb: 2, textAlign: "center" }}>
            FMV
          </Typography>
          <List>
            <Item
              primary="FMV Original Value"
              secondary={itemCurrency(valuation.fmvOriginalValue)}
            />
            <Item
              primary="FMV Audited Value"
              secondary={itemCurrency(valuation.fmvAuditedValue)}
            />
            <Item
              primary="Difference"
              secondary={itemCurrency(
                valuation.fmvAuditedValue - valuation.fmvOriginalValue
              )}
            />
            <Item
              primary="Difference (%)"
              secondary={itemPercentage(
                valuation.fmvOriginalValue,
                valuation.fmvAuditedValue
              )}
            />
          </List>
        </Grid>
      </Grid>
    </ListItem>
  );
};

const Valuations: FC<Omit<CaseValuationsProps, "caseType">> = ({
  valuations,
  caseId,
  caseCreatedAt,
  caseFirstAuditYear,
}) => {
  const currentYear = caseFirstAuditYear
    ? caseFirstAuditYear + 1
    : new Date(caseCreatedAt).getFullYear();
  const lastThreeYears = [currentYear - 4, currentYear - 3, currentYear - 2];

  const valuationsToDisplay = lastThreeYears.map((year) => {
    const matchingValuation = valuations.find((valuation) => valuation.year === year);

    return (
      matchingValuation || {
        caseId: caseId,
        id: null,
        year,
        advOriginalValue: 0,
        advAuditedValue: 0,
        fmvOriginalValue: 0,
        fmvAuditedValue: 0,
      }
    );
  });

  return (
    <Grid container size={12} spacing={2} justifyContent="space-between" direction="row">
      {valuationsToDisplay.map((valuation) => (
        <Grid size={{ xs: 12, md: 6, lg: 4 }} sx={{ mt: -2 }} key={valuation.year}>
          <OverviewBox>
            <Grid size={12}>
              <Typography variant="h5" sx={{ mb: 2, pt: 2, textAlign: "center" }}>
                {valuation.year}
              </Typography>
              <Divider />
              <CaseValuation valuation={valuation} />
            </Grid>
          </OverviewBox>
        </Grid>
      ))}
      <CustomDrawer
        title="Edit Valuation"
        content={
          <EditValuation valuations={valuationsToDisplay} drawerId="editValuation" />
        }
        drawerId={"editValuation"}
      />
    </Grid>
  );
};

const CaseValuations: FC<CaseValuationsProps> = ({
  valuations,
  caseId,
  caseType,
  caseCreatedAt,
}) => {
  const isEsa = caseType === "ESA";
  const { toggleDrawer } = useDrawer();

  return (
    <>
      {isEsa && (
        <Grid size={12}>
          <Paper>
            <BoxTop>
              <BoxHeader>Ad Valorem and FMV</BoxHeader>
              <Button
                variant="text"
                color="primary"
                startIcon={<EditNote />}
                onClick={() => toggleDrawer("editValuation")}
              >
                Edit
              </Button>
            </BoxTop>
            <Valuations
              valuations={valuations}
              caseId={caseId}
              caseCreatedAt={caseCreatedAt}
            />
          </Paper>
        </Grid>
      )}
    </>
  );
};

export default CaseValuations;
