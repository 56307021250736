// apps/case-web/src/components/pages/Overview/CaseQuickEdit.tsx

import React, { useCallback, useMemo } from "react";
import { Formik, Form as FormikForm } from "formik";
import {
  Box,
  Paper,
  IconButton,
  Typography,
  Grid2 as Grid,
} from "@mui/material";
import BoxHeader from "../../shared/BoxHeader";
import {
  Case,
  quickEditSchema,
  User,
} from "auditaware-types";
import CaseNote from "../../shared/Case/CaseNotes";
import CaseFormFields from "../../shared/Form/CaseFormFields";
import BoxTop from "../../shared/BoxTop";
import SaveButton from "../../shared/Note/SaveButton";
import CloseIcon from "@mui/icons-material/Close";
import { useUpdateCase } from "../../../hooks/cases";
import { useSnackbarContext } from "../../../hooks/useSnackbarContext";
import { useUserDoc } from "@reasongcp/react-fire-sub";
import CaseAttachments from "../../shared/Case/CaseAttachments";

interface CaseQuickEditProps {
  caseData: Case;
  closeModal: () => void;
}

interface FormValues {
  case: Case | undefined;
}

const CaseQuickEdit: React.FC<CaseQuickEditProps>
= ({ caseData, closeModal }) => {
  const [updateCase] = useUpdateCase(caseData ? caseData.id : "");
  const { setSuccess } = useSnackbarContext();
  const user = useUserDoc() as User | null;

  const initialValues: FormValues = useMemo(() => ({
    case: caseData,
  }), [caseData]);

  const handleSubmit = useCallback(
    async (values: FormValues) => {
      if (values.case) {
        await updateCase(values.case);
        setSuccess("Case updated successfully");
      }
    },
    [updateCase, setSuccess]
  );

  if (!user) return null;

  return (
    <Box sx={{ borderLeft: "2px solid #e0e0e0", px: 2, position: "relative" }}>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        sx={{ mb: 2, p: 1 }}
      >
        <Typography variant="h5">
            Quick Edit Options
        </Typography>
        <IconButton onClick={() => closeModal()}>
          <CloseIcon />
        </IconButton>
      </Grid>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={quickEditSchema}
        onSubmit={handleSubmit}>
        {({ isSubmitting }) => (
          <FormikForm>
            <Paper sx={{ pb: 2, px: 1, mb: 4 }}>
              <BoxTop>
                <BoxHeader> Case Details </BoxHeader>
              </BoxTop>
              <CaseFormFields isEditMode={true} objectNamePrefix="case" />
              <Box>
                <Grid
                  container
                  spacing={2}
                  justifyContent="space-between"
                  sx={{ mt: 2 }}
                >
                  <Grid size={6}>
                    <SaveButton
                      isSubmitting={isSubmitting}
                      isEditing={false}
                      canEdit={true}
                      fullWidth={true} />
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          </FormikForm>
        )}
      </Formik>
      <Paper >
        <Grid container spacing={2}>
          <CaseNote
            caseId={caseData.id}
            notes={caseData.notes || []}
          />
        </Grid>
      </Paper>
      <Paper sx={{ mt: 4 }}>
        <CaseAttachments
          caseId={caseData.id}
          attachments={caseData?.attachments || []}
        />
      </Paper>
    </Box>
  );
};

export default CaseQuickEdit;
