import { FC } from "react";
import Grid from "@mui/material/Grid2";

import { Case, CaseType } from "auditaware-types";

import {
  CaseOverview,
  CaseNotes,
  CaseAttachments,
  CaseResults,
  CaseFees,
  CaseValuations,
  CaseWatercraft,
} from "../Case";
import CasesTable from "./CasesTable";

type CaseDetailProps = {
  firstAuditYear?: number | null;
  case?: Case;
  cases?: Case[];
};

const defaultCases: Case[] = [];

const CaseDetails: FC<CaseDetailProps> = ({
  firstAuditYear,
  case: caseData,
  cases = defaultCases,
}) => {
  if (!caseData) {
    return null;
  }

  const { id: caseId } = caseData;

  return (
    <>
      <CaseOverview caseData={caseData} />
      <Grid container spacing={2}>
        <Grid size={{ xs: 12, md: 6, lg: 6 }}>
          <CaseNotes caseId={caseId} notes={caseData.notes} />
        </Grid>
        <Grid size={{ xs: 12, md: 6, lg: 6 }}>
          <CaseAttachments caseId={caseId} attachments={caseData.attachments} />
        </Grid>
      </Grid>
      <CasesTable cases={cases} />
      < Grid container spacing={2} marginTop={1}>
        {caseData.caseType !== CaseType.Watercraft
          && <>
            <Grid size={{ xs: 12, md: 8, lg: 8 }}>
              <CaseResults caseData={caseData} />
            </Grid>
            <Grid size={{ xs: 12, md: 4, lg: 4 }}>
              <CaseFees caseData={caseData} />
            </Grid>
          </>
        }
        {caseData.caseType === CaseType.ESA
          && <Grid container size={12}>
            <CaseValuations
              valuations={caseData.esaValuations}
              caseId={caseId}
              caseType={caseData.caseType}
              caseCreatedAt={caseData.createdAt}
              caseFirstAuditYear={firstAuditYear}
            />
          </Grid>
        }
        {caseData.caseType === CaseType.Watercraft
          && <Grid container size={12} >
            <CaseWatercraft
              watercrafts={caseData.watercraft}
              caseId={caseId}
            />
          </Grid>
        }
      </Grid >
    </>
  );
};

export default CaseDetails;
