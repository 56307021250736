import { Theme } from "@mui/material";
import { blue, green } from "@mui/material/colors";

import { SubjectType, CaseType } from "auditaware-types";

export const caseColorFromTheme = (theme: Theme, caseType: CaseType | null) => {
  switch (caseType) {
  case CaseType.Audit:
    return theme.palette.mode === "dark" ? green[200] : green[500];
  case CaseType.Inspection:
    return theme.palette.warning.main;
  case CaseType.Review:
    return theme.palette.success.main;
  case CaseType.Appraisal:
    return theme.palette.info.main;
  case CaseType.Discovery:
    return theme.palette.primary.main;
  case CaseType.ESA:
    return theme.palette.secondary.main;
  case CaseType.Watercraft:
    return blue[500];
  default:
    return theme.palette.primary.main;
  }
};

export const subjectColorFromTheme = (theme: Theme, subjectType: SubjectType | null) => {
  switch (subjectType) {
  case "Organization":
    return theme.palette.primary.main;
  case "Individual":
    return theme.palette.mode === "dark" ? green[200] : green[500];
  case "Residence":
    return theme.palette.secondary.main;
  default:
    return theme.palette.primary.main;
  }
};
