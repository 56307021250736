import { FC, Dispatch, SetStateAction, useCallback } from "react";
import { Box, Button, Divider } from "@mui/material";
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
  GridToolbarProps,
  GridColumnVisibilityModel,
  GridFilterModel,
} from "@mui/x-data-grid-pro";
import BookmarkAddOutlinedIcon from "@mui/icons-material/BookmarkAddOutlined";
import DataGridToolbarIcons from "./DataGridToolbarIcons";
import { usePersistentState } from "../../hooks/usePersistentState";
import { Favorite } from "../../lib/types/Favorite";
import { ToolbarPropsOverrides } from "@mui/x-data-grid-pro";

declare module "@mui/x-data-grid-pro" {
  // eslint-disable-next-line
  interface ToolbarPropsOverrides {
    setAnchorEl: Dispatch<SetStateAction<HTMLElement | null>>;
    setColumnVisibilityModel: (x: GridColumnVisibilityModel | null) => void;
    setFilters: (x: GridFilterModel | null) => void;
    columnVisibilityModel: GridColumnVisibilityModel | null;
    filterModel: GridFilterModel | null;
  }
}

type DataGridToolbarProps = GridToolbarProps & ToolbarPropsOverrides;


export const DataGridToolbar: FC<DataGridToolbarProps> = ({
  setColumnVisibilityModel,
  setAnchorEl,
  setFilters,
  columnVisibilityModel,
  filterModel,
}) => {
  const [favorites, setFavorites] = usePersistentState<Favorite[]>(
    "datagrid.favorites",
    []
  );

  const handleFavorite = useCallback(() => {
    setFavorites([
      ...favorites,
      { columnVisibilityModel, filterModel },
    ]);
  }, [favorites, setFavorites, columnVisibilityModel, filterModel]);

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          p: 1,
          alignItems: "center",
        }}
      >
        <DataGridToolbarIcons
          favorites={favorites}
          setFavorites={setFavorites}
          setFilters={setFilters}
          setColumnVisibilityModel={setColumnVisibilityModel}
        />
        <GridToolbarContainer>
          <Button
            variant="text"
            startIcon={<BookmarkAddOutlinedIcon />}
            onClick={handleFavorite}
          >
            Favorite
          </Button>
          <GridToolbarFilterButton />
          <GridToolbarColumnsButton ref={setAnchorEl} />
          <GridToolbarDensitySelector />
          <GridToolbarExport />
        </GridToolbarContainer>
      </Box>
      <Divider light />
    </Box>
  );
};
