// https://stackoverflow.com/questions/2901102/how-to-format-a-number-with-commas-as-thousands-separators
export const numberWithCommas = (x: number): string => {
  try {
    return x.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  } catch (error) {
    console.error(error);
    return "NaN";
  }
};
