import { FC, useMemo, useCallback, useState } from "react";
import { Box, Paper, Fab, Grid2 as Grid } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { GridColumnGroupingModel } from "@mui/x-data-grid-pro";

import { Subject, Case } from "auditaware-types";
import { CustomDrawer, useDrawer } from "auditaware-ui";

import { DataGrid } from "../../shared/DataGrid";
import Spinner from "../../shared/LogoSpinner/Spinner";
import CreateSubject from "../../shared/Subject/CreateSubject";
import { useSubjects } from "../../../hooks/subjectHooks";
import useOverviewColumns from "../../../hooks/useOverviewColumns";
import { mostRecentCaseFilterModel } from "../../../lib/filterModels";
import {
  defaultColumnVisibilityModel,
  splitViewColumnVisibilityModel
} from "../../../lib/columnVisibilityModels";
import CaseQuickEdit from "./caseQuickEdit";

type OverviewRow = {
  subject: Subject;
  case?: Case;
};

const TERMINAL_STATUSES = ["CANCELLED", "COMPLETED"];

const columnGroupingModel: GridColumnGroupingModel = [
  {
    groupId: "types",
    headerName: "Types",
    children: [{ field: "subject.subjectType" }, { field: "case.caseType" }],
  },
  {
    groupId: "subject",
    headerName: "Subject",
    children: [
      { field: "subject.subjectData.score" },
      { field: "subject.location.parcelId" },
      { field: "subject.externalId" },
      { field: "subject.contact.primaryName" },
      { field: "subject.contact.naics" },
      { field: "subject.location.countyName" },
      { field: "subject.location.jurisdictionName" },
      { field: "subject.location.address" },
      { field: "subject.location.city" },
      { field: "subject.location.stateAbbr" },
      { field: "subject.location.zipCode" },
      { field: "subject.contact.website" },
      { field: "subject.contact.email" },
      { field: "subject.contact.phoneNumber" },
    ],
  },
  {
    groupId: "case",
    headerName: "Case",
    children: [
      { field: "status" },
      { field: "case.cycle" },
      { field: "case.location.parcelId" },
      { field: "case.location.countyName" },
      { field: "case.location.jurisdictionName" },
      { field: "case.assignee.displayName" },
      { field: "case.scheduledOn" },
      { field: "case.confirmationSentOn" },
      { field: "case.documentsReceivedOn" },
      { field: "case.primaryContact.displayName" },
      { field: "case.primaryContact.phoneNumber" },
      { field: "case.primaryContact.email" },
      { field: "case.location.address" },
      { field: "case.location.city" },
      { field: "case.location.stateAbbr" },
      { field: "case.location.zipCode" },
      { field: "mostRecent" },
      { field: "active" },
      { field: "details" },
      { field: "quickEdit" },
    ],
  },
];

const findCaseBySubjectandCaseId
= (subjects: Subject[], caseId: string | undefined, subjectId: string | undefined) => {
  return subjects.find((s) => s.id === subjectId)?.cases?.find((c) => c.id === caseId);
};

const Overview: FC = () => {
  const { error, loading, subjects } = useSubjects({ loadCases: true });
  const columns = useOverviewColumns();
  const { toggleDrawer } = useDrawer();
  const [isSplitView, setIsSplitView] = useState(false);
  const [
    quickEditCaseIdandSubjectId,
    setQuickEditCaseIdandSubjectId
  ] = useState<{ caseId: string, subjectId: string } | null>(null);

  const selectQuickEditCase = useCallback(
    (caseId: string, subjectId: string) => {
      setIsSplitView(true);
      setQuickEditCaseIdandSubjectId({ caseId, subjectId });
    },
    [setIsSplitView, setQuickEditCaseIdandSubjectId]
  );

  const closeQuickEdit = useCallback(() => {
    setQuickEditCaseIdandSubjectId({ caseId: "", subjectId: "" });
    setIsSplitView(false);
  }, [setQuickEditCaseIdandSubjectId, setIsSplitView]);

  const rows: OverviewRow[] = useMemo(
    () =>
      (subjects || []).flatMap((subject) => {
        const cases = [...(subject.cases || [])].sort((a, b) =>
          a.createdAt > b.createdAt ? 1 : -1
        );

        if (cases.length === 0) {
          return { id: subject.id, subject, mostRecent: true, active: false };
        }

        return cases.map((c, i) => ({
          id: c.id,
          subject,
          case: c,
          mostRecent: i === 0,
          active: !TERMINAL_STATUSES.includes(c.status as string),
          setQuickEditCaseId: () => selectQuickEditCase(c.id, subject.id),
        }));
      }),
    [subjects, selectQuickEditCase]
  );

  const handleFabClick = useCallback(() => {
    toggleDrawer("createSubject");
  }, [toggleDrawer]);

  if (loading || error) return <Spinner />;
  if (!subjects) return null;

  const quickEditCase
  = findCaseBySubjectandCaseId(subjects,
    quickEditCaseIdandSubjectId?.caseId, quickEditCaseIdandSubjectId?.subjectId);

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid
          size={isSplitView ? { xs: 12, md: 5, lg: 7 } : { xs: 12 }}
          sx={{
            height: isSplitView ? "calc(100vh - 172px)" : "100%",
            overflowY: isSplitView ? "scroll" : "hidden",
          }}
        >
          <Paper
            sx={{
              display: "flex",
              flexDirection: "column",
              borderRadius: "12px",
            }}
          >
            <DataGrid
              rows={rows}
              columns={columns}
              columnGroupingModel={columnGroupingModel}
              settingsPrefix="overview"
              defaultFilterModel={mostRecentCaseFilterModel}
              defaultColumnVisibilityModel={
                isSplitView
                  ? splitViewColumnVisibilityModel
                  : defaultColumnVisibilityModel
              }
            />
          </Paper>
          <Fab
            sx={{ position: "fixed", bottom: "4rem", right: "6rem", boxShadow: 8 }}
            color="info"
            aria-label="add subject"
            onClick={handleFabClick}
            title="Add Subject"
            type="button"
          >
            <AddIcon />
          </Fab>
          <CustomDrawer
            title="Create Subject"
            drawerId="createSubject"
            content={<CreateSubject />}
          />
        </Grid>
        <Grid
          size={{ xs: 12, md: 7, lg: 5 }}
          sx={{
            transition: "all 0.3s ease-in-out",
            transformOrigin: "left",
            height: isSplitView ? "calc(100vh - 172px)" : "100%",
            overflowY: "scroll",
          }}
        >
          {quickEditCase && (
            <CaseQuickEdit
              caseData={quickEditCase}
              closeModal={closeQuickEdit}
            />
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default Overview;
