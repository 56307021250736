import { FC } from "react";
import {
  Box,
  Button,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  IconButton,
  Paper,
  Grid2 as Grid,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";

import { useAuth } from "@reasongcp/react-fire-sub";
import { Note } from "auditaware-types";
import { CustomDrawer, useDrawer } from "auditaware-ui";

import CreateNote from "../Note/CreateNote";
import EditNote from "../Note/EditNote";
import InitialsAvatar from "../InitialsAvatar";
import OverviewBox from "../OverviewBox";
import BoxHeader from "../BoxHeader";
import BoxTop from "../BoxTop";
import useCoercedDateScalar from "../../../hooks/useCoercedDateScalar";

interface CaseNotesProps {
  notes: Note[];
  caseId: string;
}

const CaseNote: FC<{ note: Note }> = ({ note }) => {
  const { toggleDrawer } = useDrawer();
  const { author, text } = note;
  const safeUpdatedAt = useCoercedDateScalar(note.updatedAt);

  const user = useAuth();
  const canEdit = user?.uid === author.id;

  return (
    <ListItem
      alignItems="flex-start"
      divider={true}
      sx={{
        alignItems: "center",
      }}
    >
      <ListItemAvatar>
        <InitialsAvatar user={author} />
      </ListItemAvatar>
      <ListItemText
        primary={
          <Box
            mb={1}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h6">{author.displayName || author.email}</Typography>
            <Box component="div" display="flex" alignItems="center">
              <Typography variant="subtitle2" color="text.secondary" component="div">
                <small>{safeUpdatedAt.toLocaleString()}</small>
              </Typography>
              {canEdit && (
                <>
                  <IconButton
                    aria-label="Edit Note"
                    title="Edit Note"
                    onClick={() => toggleDrawer(`editNote-${note.id}`)}
                  >
                    <EditIcon color="primary" fontSize="small" />
                  </IconButton>
                  <CustomDrawer
                    title="Edit Note"
                    content={<EditNote note={note} />}
                    drawerId={`editNote-${note.id}`}
                  />
                </>
              )}
            </Box>
          </Box>
        }
        secondary={
          <Typography
            variant="body2"
            component="div"
            sx={{
              whiteSpace: "wrap",
            }}
          >
            {text}
          </Typography>
        }
      />
    </ListItem>
  );
};

const Notes: FC<{ notes: Note[] }> = ({ notes }) => {
  const sortedNotes = [...notes].sort(
    (a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
  );
  if (sortedNotes.length === 0) {
    return (
      <List>
        <ListItem>
          <Typography component="p" sx={{ py: 2 }}>
            No notes available
          </Typography>
        </ListItem>
      </List>
    );
  }

  return (
    <List>
      {sortedNotes.map((note, idx) => {
        return <CaseNote key={idx} note={note} />;
      })}
    </List>
  );
};

const CaseNotes: FC<CaseNotesProps> = ({ notes, caseId }) => {
  const { toggleDrawer } = useDrawer();

  return (
    <Grid size={{ xs: 12, md: 12, lg: 12 }} sx={{ marginBottom: "15px" }}>
      <Paper>
        <BoxTop>
          <BoxHeader>Notes</BoxHeader>
          <div>
            <Button
              type="submit"
              variant="text"
              color="primary"
              startIcon={<AddIcon />}
              sx={{ borderRadius: 24 }}
              onClick={() => toggleDrawer("createNote")}
            >
              Note
            </Button>
            <CustomDrawer
              title="Add Note"
              content={<CreateNote caseId={caseId} />}
              drawerId={"createNote"}
            />
          </div>
        </BoxTop>
        <OverviewBox>
          <Grid container spacing={2}>
            <Grid size={12}>
              <Notes notes={notes} />
            </Grid>
          </Grid>
        </OverviewBox>
      </Paper>
    </Grid>
  );
};
export default CaseNotes;
export { Notes };
