import { FC, useMemo, JSX } from "react";
import { Paper, Grid2 as Grid } from "@mui/material";
import {
  DataGridPro,
  GridColDef,
  GridColumnGroupingModel,
  GridFilterItem,
  GridFilterModel,
  GridLogicOperator,
  GridRenderCellParams,
} from "@mui/x-data-grid-pro";
import { Subject, Case, User } from "auditaware-types";
import { Link } from "react-router-dom";

import DataGridExportToolbar from "../../shared/DataGridExportToolbar";
import BoxHeader from "../../shared/BoxHeader";
import BoxTop from "../../shared/BoxTop";
import Spinner from "../../shared/LogoSpinner/Spinner";
import { useSubjects } from "../../../hooks/subjectHooks";
import useAssignees from "../../../hooks/useAssignees";
import { usePersistentState } from "../../../hooks/usePersistentState";

type SubjectAndCase = {
  subject: Subject;
  case?: Case;
};

type ReportRow = Pick<User, "displayName" | "id"> & Record<string, JSX.Element>;

const SEP = "__" as const;

const typeKey = (subject: Subject, c?: Case) =>
  [subject.subjectType, c?.caseType || "No Case", c?.status || "NONE"].join(SEP);

// This function returns a function that updates the filters in local storage
// To be used as an onclick handler for the grid cells
const generateHandleCellClick
  = (key: string, id: string, setFilters: (filters: GridFilterModel) => void) => {
    return (key: string) => {
      const [subjectType, caseType, status] = key.split(SEP);

      const filterItems: (GridFilterItem | null)[] = [
        {
          field: "subject.subjectType",
          operator: "is",
          value: subjectType,
          id: "subjectTypeFilter",
        },
        // No case is not a filterable condition
        // So we look for empty caseType values
        caseType === "No Case" ? {
          field: "case.caseType",
          operator: "isEmpty",
          id: "noCaseFilter",
        } : {
          field: "case.caseType",
          operator: "is",
          value: caseType,
          id: "caseTypeFilter",
        },
        // NONE is not a filterable condition
        // So we look for empty status values
        status === "NONE" ? {
          field: "status",
          operator: "isEmpty",
          id: "noStatusFilter",
        } : {
          field: "status",
          operator: "is",
          value: status,
          id: "statusFilter",
        },
        // total is a special case where we don't filter out assignee
        id === "total" ? null : {
          field: "case.assignee.displayName",
          operator: "is",
          value: id ?? "unassigned",
          id: "assigneeFilter",
        },
      ].filter(Boolean); // remove nulls

      setFilters({
        items: filterItems as GridFilterItem[],
        logicOperator: GridLogicOperator.And,
      });
    };
  };

const StatusReport: FC = () => {
  const { error, loading, subjects } = useSubjects({ loadCases: true });
  const assignees = useAssignees();
  const [, setFilters] = usePersistentState<GridFilterModel | null>(
    "overview.filters",
    null
  );

  // convert subject objects into SubjectAndCase objects
  const records = useMemo(
    () =>
      subjects.flatMap<SubjectAndCase>((subject: Subject) => {
        const cases = subject.cases || [];

        if (!cases.length) return { subject };

        return cases.map((c) => ({ subject, case: c }) as SubjectAndCase);
      }),
    [subjects]
  );

  // count number of unique records
  const types: Record<string, number> = useMemo(
    () =>
      records.reduce(
        (acc, { subject: s, case: c }) => {
          const type = typeKey(s, c);
          acc[type] = (acc[type] || 0) + 1;
          return acc;
        },
        {} as Record<string, number>
      ),
    [records]
  );

  // Organize types into hierarchies for complex headers
  const columnGroups: GridColumnGroupingModel = useMemo(() => {
    // split the "_" sepearte type string into a nested object structure
    const groups = Object.keys(types).reduce(
      (acc, type) => {
        const [subjectType, caseType, status] = type.split(SEP);

        if (!acc[subjectType]) acc[subjectType] = {};
        if (!caseType) {
          acc[subjectType]["No Case"] = { NONE: type };
          return acc;
        }

        if (!acc[subjectType][caseType]) acc[subjectType][caseType] = {};
        if (!acc[subjectType][caseType][status]) {
          acc[subjectType][caseType][status] = type;
        }

        return acc;
      },
      {} as Record<string, Record<string, Record<string, string>>>
    );

    // Convert groups (nested Records) into nested header objects
    return Object.keys(groups).reduce((acc, subjectType) => {
      const caseTypes = groups[subjectType];

      const caseGroups = Object.keys(caseTypes).reduce((acc, caseType) => {
        const statuses = caseTypes[caseType];

        const statusGroups = Object.keys(statuses).map((status) => ({
          field: statuses[status],
        }));

        return [
          ...acc,
          {
            groupId: caseType,
            headerName: caseType,
            children: statusGroups,
          },
        ];
      }, [] as GridColumnGroupingModel);

      return [
        ...acc,
        {
          groupId: subjectType,
          headerName: subjectType,
          children: caseGroups,
        },
      ];
    }, [] as GridColumnGroupingModel);
  }, [types]);

  const rows = useMemo(() => {
    const unassigned = { displayName: "Unassigned", id: undefined };
    const total = { displayName: "Total", id: "total" };
    // create rows for unassinged (default) and all asignees
    const topRows = [unassigned, ...assignees, total].map(({ displayName, id }) => {
      // filter for records related to current assignee
      // special case for total: use all records
      const assignments = records
        .filter(({ case: c }) => id === "total" ? true : id === c?.assignee?.id);

      // count number of assignments to each assignee
      const rowRecords = assignments.reduce(
        (acc, { subject, case: c }) => {
          const key = typeKey(subject, c);
          acc[key] = (acc[key] || 0) + 1;
          return acc;
        },
        {} as Record<string, number>
      );

      // Filter the rows by taking all values in the
      // records and summing them. If <= 0 then remove
      const rowTotal = Object.values(rowRecords)
        .reduce((acc: number, key: number) => acc + key, 0);
      if (rowTotal <= 0) return null;


      // convert grid cells into clickable elements by returning a JSX element
      // with an onClick handler that updates the filter model
      const rowLinksObject = Object.keys(rowRecords).reduce((acc, key) => {
        acc[key] = (
          <Link
            to="/"
            onClick={() =>
              generateHandleCellClick(key, id || "unassigned", setFilters)(key)}
            style={{ color: "inherit", cursor: "pointer" }}
          >
            {rowRecords[key]}
          </Link>
        );
        return acc;
      }, {} as Record<string, JSX.Element>);

      // populate the row with name, id, and clickable div
      return {
        displayName: displayName,
        id: id || "unassigned",
        ...rowLinksObject,
      } as ReportRow;
    }).filter(Boolean); // filter out null rows

    return [...topRows];
  }, [assignees, records, setFilters]);

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: "displayName",
        headerName: "Assignee",
        flex: 1,
      },
      ...Object.keys(types)
        .sort()
        .map((type) => ({
          field: type,
          headerName: type.split(SEP).pop(),
          flex: 1,
          renderCell: (params: GridRenderCellParams) => {
            const value = params.value;
            if (typeof value === "undefined") {
              // when generating the rows we only define records that have a value
              // so if we see an undefined value, we know it's because there was no record
              // for that cell so we should display a 0
              return <div>0</div>;
            }
            return value;
          },
        })),
    ],
    [types]
  );

  if (error || loading) return <Spinner />;

  return (
    <Grid size={{ xs: 12, md: 12, lg: 12 }}>
      <Paper>
        <BoxTop>
          <BoxHeader>Status Report</BoxHeader>
        </BoxTop>
        <DataGridPro
          rows={rows}
          columns={columns}
          columnGroupingModel={columnGroups}
          slots={{ toolbar: DataGridExportToolbar }}
        />
      </Paper>
    </Grid>
  );
};

export default StatusReport;
