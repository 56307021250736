import { FC, useCallback, useMemo } from "react";
import { useFormikContext, Form as FormikForm } from "formik";
import { Box, Button, Grid2 as Grid } from "@mui/material";
import { TextField, useDrawer } from "auditaware-ui";
import { NoteInput } from "auditaware-types";

import { DrawerFormTypes } from "../../../constants/forms";
import SaveButton from "./SaveButton";

const Form: FC<DrawerFormTypes> = ({ drawerId }) => {
  const { isSubmitting, values } = useFormikContext<NoteInput>();
  const { toggleDrawer } = useDrawer();

  const handleCancelClick = useCallback(() => {
    toggleDrawer(drawerId);
  }, [drawerId, toggleDrawer]);

  const isEditing = useMemo(() => Object.keys(values).includes("updatedAt"), [values]);

  return (
    <FormikForm>
      <Grid container spacing={2}>
        <Grid size={12}>
          <TextField
            type="textarea"
            name="text"
            id="text"
            label="Note"
            multiline
            rows={8}
            fullWidth
          />
        </Grid>
      </Grid>
      <Box
        sx={{
          position: "fixed", // Set position to fixed
          bottom: 0, // Fixed at the bottom
          right: 0,
          width: "800px",
          borderTop: "1px solid #e0e0e0",
          display: "flex",
          justifyContent: "space-between",
          padding: ".5rem",
          zIndex: 1,
        }}
      >
        <SaveButton
          isSubmitting={isSubmitting}
          isEditing={isEditing}
          canEdit={true}
          fullWidth={true}
        />
        <Button
          type="reset"
          variant="outlined"
          size="large"
          color="secondary"
          disabled={isSubmitting}
          fullWidth={true}
          sx={{ ml: 2 }}
          onClick={handleCancelClick}
        >
          Cancel
        </Button>
      </Box>
    </FormikForm>
  );
};

export default Form;
